/**
 * scroll ventana a objeto indicado
 * @param {[type]} _duration  [duracion del scroll]
 * @param {[type]} _targetDOM [objeto a donde va]
 * @param {[type]} _delay     [retraso en ejecución del scroll]
 * @param {[type]} _offset     [distancia extra movimiento]
 */
function PLG_scrollTo(_duration,_targetDOM,_delay,_offset){
    TweenMax.to(window, _duration, {scrollTo:{y:$(_targetDOM).offset().top+_offset,autoKill:false},delay:_delay,ease:Power3.easeInOut});
}

/**
 * [PLG_showmenu muestra menu hamburguesa]
 */
function PLG_showMenu(){
	TweenMax.to("#menu_desplegable", 1, {left:0, ease:Power4.easeOut});
	TweenMax.to(".contenedor-header", 1, {left:anchoventana/2+anchoventana/10, ease:Power4.easeOut});
	TweenMax.to("section", 1, {left:anchoventana/10, ease:Power4.easeOut});
}

/**
 * [PLG_showmenu oculta el menu hamburguesa]
 */
function PLG_closeMenu(_callback){
	$('#menu_desplegable').collapse('hide');
	desplazaX=anchoventana/2*-1-50;
	if (anchoventana<md){
			desplazaX=-300;
	}

	TweenMax.to("#menu_desplegable", 0.5, {left:desplazaX, ease:Power1.easeOut});
	TweenMax.to(".contenedor-header", 0.5, {left:anchoventana/2, ease:Power1.easeOut});
	TweenMax.to("section", 0.5, {left:0, ease:Power1.easeOut,onComplete:_callback});
}

/**
 * [PLG_toggleModalLogin muestra/oculta el modal de login/registro]
 */
function PLG_toggleModalLogin() {
	if($( '#selector-menu-login' ).hasClass("abierto")){
		PLG_closeModalLogin();
	}else{
		PLG_showModalLogin();
	}
}
function PLG_showModalLogin(){
	if(anchoventana<md){
		posY=50;
		posX=270;
	}else{
		posY=70;
		posX=260;
	}
	TweenMax.fromTo("#selector-menu-login", 0.5, {
	  top:posY+30,//$(".js-btn-header-login").offset().top+130,
	  left:$(".js-btn-header-login").offset().left-posX,
	  opacity:0
	},{
	  top:posY,//$(".js-btn-header-login").offset().top+55,
	  left:$(".js-btn-header-login").offset().left-posX,
	  autoAlpha:1,
	  display:'block',
	  ease:Power4.easeOut
	});
	$( '#selector-menu-login,.js-btn-header-login' ).addClass("abierto");
}
function PLG_closeModalLogin(){
    TweenMax.to("#selector-menu-login", 0.5, {
      top:100,//$(".js-btn-header-login").offset().top+100,
      left:$(".js-btn-header-login").offset().left-260,
      opacity:0,
      ease:Power4.easeOut
    });
    $('#selector-menu-login,.js-btn-header-login' ).removeClass("abierto");
}


/**
 * [PLG_showModalAlerta :: mostrar tip con texto apuntando al objeto]
 * @param {[type]} _texto     [texto dentro del tip]
 * @param {[type]} _objTarget [objeto donde apunta la flecha]
 */
function PLG_showModalAlerta(_texto,_objTarget){
    $("#modal-tip .modal-body").html(_texto);
    var elementoY=$(_objTarget).offset().top-$(document).scrollTop()+$(_objTarget).outerHeight();
    var elementoX=$(_objTarget).offset().left+($(_objTarget).outerWidth()/2);
    TweenMax.fromTo("#modal-tip .modal-dialog", 0.5, {
    top:elementoY+30,
    left:elementoX,
    opacity:0
    },{
    top:elementoY,
    left:elementoX,
    autoAlpha:1,
    display:'block',
    ease:Power4.easeOut
    });
    $("#modal-tip").modal("show");
}


function PLG_showModalAlerta(_titulo,_texto){
    $("#modal-alerta .modal-title").html(_titulo);
    $("#modal-alerta .modal-body").html(_texto);
    $("#modal-alerta").modal("show");
}



function PLG_showModalAlerta(_titulo,_texto){
    $("#modal-alerta .modal-title").html(_titulo);
    $("#modal-alerta .modal-body").html(_texto);
    $("#modal-alerta").modal("show");
}

/**
 * [feedback mensaje feedback ajax para un botón::: requiere un contenedor "js-contenedor-feedback"]
 * @param  {[type]} _titutlo [description]
 * @param  {[type]} _texto   [description]
 * @param  {[type]} _tipo    [description]
 */
function feedback(_titutlo,_texto,_tipo){
	var contenedor=$(".js-contenedor-feedback");
		contenedor.html('<div class="alert alert-'+_tipo+' fade mt-3 show" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button><strong>'+_titutlo+'</strong> '+_texto+'</div>');
}

/**
 * COOKIES
 */
 function checkCookie() {
    var user = getCookie("tlcpromo-cookies-tecnica");
    if (user != "") {
        //hay cookies 
       // console.log('tiene cook');
        $("#modal-cookies").fadeOut();
    } else {
        $("#modal-cookies").fadeIn();
        console.log('no tiene');
    }
} 
function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}
function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}


/**
 * copy to clipboard
 */
function copyToClipboard(elem) {
	  // create hidden text element, if it doesn't already exist
    var targetId = "_hiddenCopyText_";
    var isInput = elem.tagName === "INPUT" || elem.tagName === "TEXTAREA";
    var origSelectionStart, origSelectionEnd;
    if (isInput) {
        // can just use the original source element for the selection and copy
        target = elem;
        origSelectionStart = elem.selectionStart;
        origSelectionEnd = elem.selectionEnd;
    } else {
        // must use a temporary form element for the selection and copy
        target = document.getElementById(targetId);
        if (!target) {
            var target = document.createElement("textarea");
            target.style.position = "absolute";
            target.style.left = "-9999px";
            target.style.top = "0";
            target.id = targetId;
            document.body.appendChild(target);
        }
        target.textContent = elem.textContent;
    }
    // select the content
    var currentFocus = document.activeElement;
    target.focus();
    target.setSelectionRange(0, target.value.length);
    
    // copy the selection
    var succeed;
    try {
    	  succeed = document.execCommand("copy");
    } catch(e) {
        succeed = false;
    }
    // restore original focus
    if (currentFocus && typeof currentFocus.focus === "function") {
        currentFocus.focus();
    }
    
    if (isInput) {
        // restore prior selection
        elem.setSelectionRange(origSelectionStart, origSelectionEnd);
    } else {
        // clear temporary content
        target.textContent = "";
    }
    return succeed;
}