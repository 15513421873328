initListeners=function(){
  $(window).scroll(function(event) {
    valorScroll=$(window).scrollTop();

    // añade clase "enpantalla" cuando aparece en pantalla
    // $(".js-aparece-scroll").each(function(index, el) {
    //   if($(this).offset().top+$(this).height()<=valorScroll+$(window).height() && $(this).offset().top+$(this).height()>=valorScroll){
    //     $(this).addClass("enpantalla");
    //   }else{
    //     $(this).removeClass("enpantalla");
    //   }
    // });
    $($(".js-scrollspy").get().reverse()).each(function(index, el) {
   //$(".js-scrollspy").reverse().each(function(index, el) {
      var ojb=$($(this).attr("href"));
      if (ojb.length > 0){
        var itemsScrollSpy=$(this).closest(".js-content-scrollspy").find($(this).closest(".js-content-scrollspy").data("item-scrollspy"));
      
        var itemActivo=$(this).closest($(this).closest(".js-content-scrollspy").data("item-scrollspy"));
        var parteVisible=150;//ojb.height()
        //console.log($(this).closest(".js-content-scrollspy").data("item-scrollspy"));
        if(ojb.offset().top+150<=valorScroll+$(window).height() && ojb.offset().top+150>=valorScroll){
          $(itemsScrollSpy).removeClass("active");
          itemActivo.addClass("active");
        }else{
          itemActivo.removeClass("active");
        }
      }  
      
    });

$('#navbarNav').collapse('hide');


  });

  /**
   * LISTENER validador formularios
   */
   $('.js-form-validator').validator(customValidations()).on('submit', function (e) {
    $('.alert').hide();
    //console.log('oculta1:'+$(this).attr('id'));
    
    if ($(this).data('enviar-ajax') == undefined || $(this).data('enviar-ajax') == true){
          enviar_por_ajax = true;
    }else{
          enviar_por_ajax = false;
    }

    if (e.isDefaultPrevented() ) {
     
          $('.chosen-select').each(function(){
              element = $(this);
              id_chosen = element.attr('id');
              if (element.val() == ''){
                $('#error_'+id_chosen).addClass('has-error');
                $('#error_'+id_chosen).removeClass('has-success');
              }
              else{
                $('#error_'+id_chosen).addClass('has-success');
                $('#error_'+id_chosen).removeClass('has-error');
              }
          }); 
          lista_errores = "";
          $(this).find('.has-error').each(function(index, element){
            if ($(this).attr('data-mensaje-error') !== 'undefined' && $(this).attr('data-mensaje-error') !== undefined && $(this).attr('data-mensaje-error') !== ''){
              lista_errores += '<li>'+$(this).attr('data-mensaje-error')+'</li>'; 
            }else{
              //lista_errores += '<li>'+$(this).find('label').html()+'</li>';            
            }
          });

          if (lista_errores !=''){
              lista_errores = '<ul>'+lista_errores+'</ul>';
          }
          

          // handle the invalid form...
         

          // handle the invalid form...
         // console.log("****"+$(this).attr("class"));
          alerta = $(this).data('alerta');

          if ($(this).attr('data-mensaje-error') !== 'undefined' && $(this).attr('data-mensaje-error') !== undefined && $(this).attr('data-mensaje-error') !== ''){
              //$('#myModal').mostrar_msg("¡Lo sentimos!", $(this).data('mensaje-error')+lista_errores);
              $('#'+alerta).find('span').html($(this).attr('data-mensaje-error')+lista_errores);
              $('#'+alerta).show();
              PLG_scrollTo(1,$('#'+alerta),0,-120);
          }else{
              //$('#myModal').mostrar_msg("¡Lo sentimos!", "Revisa todos los datos antes de continuar"+lista_errores);              
              $('#'+alerta).find('span').html("Revise todos los datos antes de continuar"+lista_errores);
              $('#'+alerta).show();
          }
                //console.log('muestra???');

    }
    else{
      //hay que validar de nuevo los chosen porque aunque dan alerta, dejan pasar
        $('.chosen-select').each(function(){
          element = $(this);
          id_chosen = element.attr('id');
          if (element.val() == ''){
            $('#error_'+id_chosen).addClass('has-error');
            $('#error_'+id_chosen).removeClass('has-success');
          }
          else{
            $('#error_'+id_chosen).addClass('has-success');
            $('#error_'+id_chosen).removeClass('has-error');
          }
      }); 
      lista_errores = "";
      $(this).find('.has-error').each(function(index, element){
        if ($(this).attr('data-mensaje-error') !== 'undefined' && $(this).attr('data-mensaje-error') !== undefined && $(this).attr('data-mensaje-error') !== ''){
          lista_errores += '<li>'+$(this).attr('data-mensaje-error')+'</li>'; 
        }else{
          //lista_errores += '<li>'+$(this).find('label').html()+'</li>';            
        }
      });

      if (lista_errores !=''){
          lista_errores = '<ul>'+lista_errores+'</ul>';
      }
      

      // handle the invalid form...
    

     

      if (lista_errores == ''){
              if ($(this).data('spinner-holder') != ''){
                spinner_holder = $($(this).data('spinner-holder')).find('.js-spinner-holder');
              }else{
                $('body').find('.js-spinner-holder');
              }
              spinner_holder.fadeIn();

              // Si hay que enviarlo por ajax ejecutamos simpleAjaxForm, si no, dejamos que haga el submit
              $('.alert').hide();
                  // console.log('oculta2');

              $(this).find("button[type='submit']").addClass('loader');
              //console.log('poner loader');
              if (enviar_por_ajax){
                //console.log('enviar ajax');
                if ($(this).data('archivos')){
                  //console.log('ini');
                    $(this).simpleAjaxFormFiles();
                }
                else{
                  //console.log('ini2');
                    $(this).simpleAjaxForm();
                  //console.log('fin');  
                }    
                    e.preventDefault();
              }
        }   
        else{

           // handle the invalid form...
          // console.log("****"+$(this).attr("class"));
            alerta = $(this).data('alerta');

            if ($(this).attr('data-mensaje-error') !== 'undefined' && $(this).attr('data-mensaje-error') !== undefined && $(this).attr('data-mensaje-error') !== ''){
                //$('#myModal').mostrar_msg("¡Lo sentimos!", $(this).data('mensaje-error')+lista_errores);
                $('#'+alerta).find('span').html($(this).attr('data-mensaje-error')+lista_errores);
                $('#'+alerta).show();
                PLG_scrollTo(1,$('#'+alerta),0,-120);
            }else{
                //$('#myModal').mostrar_msg("¡Lo sentimos!", "Revisa todos los datos antes de continuar"+lista_errores);              
                $('#'+alerta).find('span').html("Revise todos los datos antes de continuar"+lista_errores);
                $('#'+alerta).show();
            }
            e.preventDefault();
        }     

    } 
  });

  $('.solo-letras').on('keypress', function(event) {
    /* Act on the event */
    return soloLetras(event);
  });
  $('.solo-numeros').on('keypress', function(event) {
    /* Act on the event */
    return soloNumeros(event);
  });
  $('.formato-fecha').on('keypress', function(event) {
    /* Act on the event */
    return formatoFecha(event);
  });
  $('.js-forzar-mayusculas').on('keypress', function(){
    //console.log('paso');
     valor = $(this).val().toUpperCase();
  //   console.log(valor);
     $(this).val(valor); 
  });


function soloLetras(e){
       key = e.keyCode || e.which;
       tecla = String.fromCharCode(key).toLowerCase();
       letras = " áéíóúabcdefghijklmnñopqrstuvwxyz-_";
       especiales = "8-37-39-46";
       tecla_especial = false;
       for(var i in especiales){
            if(key == especiales[i]){
                tecla_especial = true;
                break;
            }
        }

        if(letras.indexOf(tecla)==-1 && !tecla_especial){
            return false;
        }
    }
function soloNumeros(e){
       key = e.keyCode || e.which;
       tecla = String.fromCharCode(key).toLowerCase();
       letras = "0123456789";
       especiales = "8-37-39-46";

       tecla_especial = false
       for(var i in especiales){
            if(key == especiales[i]){
                tecla_especial = true;
                break;
            }
        }

        if(letras.indexOf(tecla)==-1 && !tecla_especial){
            return false;
        }
    }

function formatoFecha(e){
       key = e.keyCode || e.which;
       tecla = String.fromCharCode(key).toLowerCase();
       letras = "/0123456789";
       especiales = "8-37-39-46";

       tecla_especial = false
       for(var i in especiales){
            if(key == especiales[i]){
                tecla_especial = true;
                break;
            }
        }

        if(letras.indexOf(tecla)==-1 && !tecla_especial){
            return false;
        }
}
    
  /**
   * LISTENER para mostrar/ocultar objetos
   */
  $(".js-toggle-div").click(function(){
    var obj=$(this).data("toggle-div");
    $(obj).toggleClass("desactivo");
  })

  /**
   * LISTENER para mostrar/ocultar objetos
   */
  $(".js-hidden-div").click(function(){
    var obj=$(this).data("hidden-div");
    $(obj).hide();
  })

  /**
   * LISTENER menus con ancla
   */
  $(".js-menu-ancla").click(function(e){
    if(anchoventana>=md){
      e.preventDefault();
      var ancla=$(this).attr("href");
      var offset=$(this).data("menuancla-offset");
      if(offset=="" || offset==undefined){
        offset=0;
      }
      //console.log('offset:: '+offset+' href:: '+href);
      PLG_scrollTo(1,ancla,0,offset,0);

    }
  }) 

  /**
   * LISTENER para despliegue menú hamburguesa
   */
  $('#menu_desplegable').on('show.bs.collapse', function () {
    PLG_showMenu();
   // PLG_closeModalLogin();
  });
  $("#menu_desplegable .close").click(function(){
    PLG_closeMenu();
  });

  /**
   * LISTENER para mostrar modal login
   */
  $(".js-btn-header-login").click(function(){
    if($("#menu_desplegable").hasClass("show")){
      PLG_closeMenu(function(){
        // PLG_toggleModalLogin();
      });
    }else{
      PLG_toggleModalLogin();
    }
  });

  /**
   * LISTENER para mostrar modal alerta
   */
  $(".js-modal-alerta").click(function(){
    PLG_showModalAlerta($(this).data("alerta-titulo"),$(this).data("alerta-texto"));
  });

  /**
   * LISTENER cierre cookies
   */
   $("#modal-cookies #close-cookies").click(function(){
    $("#modal-cookies").fadeOut();
    setCookie("tlcpromo-cookies-tecnica", "true", 365);
  });
  checkCookie();

  /**
   * LISTENER copiar de un input
   */
  $(".js-btn-copy").click(function() {
      var target=$(this).data("target-copy");
      copyToClipboard(document.getElementById(target));
  });


/**
 * funcion parallax de movimiento raton
 * [necesita clase js-parallaxElement]
 * [atributo data-movimientopxy] -> para indicar velocidad Y
 * [atributo data-movimientopxx] -> para indicar velocidad X
 */
  $( document ).mousemove( function( e ) {
    //variables globales
    ancho=$(window).width()/2;
    alto=$(window).height()/2;
    posx=e.clientX-ancho;
    posy=e.clientY-alto;
    pctx=parseInt(posx*100/ancho,10);
    pcty=parseInt(posy*100/alto,10);

    $('.js-parallaxMouse').each(function(index, el){
      tempx=pctx*parseInt($(el).attr("data-movimientopxx"),12)/100*-1;
      tempy=pcty*parseInt($(el).attr("data-movimientopxy"),12)/100*-1;
      $(el).css({
        '-ms-transform': 'translate('+tempx+'px, '+tempy+'px)',
        '-webkit-transform': 'translate('+tempx+'px, '+tempy+'px)',
        'transform': 'translate('+tempx+'px, '+tempy+'px)'
      });
    });
    $(".js-alphaElement").each(function(index, el) {
      if ($(window).scrollTop() < parseInt($(this).attr("data-topescroll"),10)){
        pct=e.clientX*100/$(window).width();
        $(el).find("img").css({
          'opacity': pct/100,
        });
      }
    });
  });


/**
 * funcion parallax de movimiento scroll
 * [necesita clase js-parallaxScroll]
 * [atributo data-movimientopxy] -> para indicar velocidad Y
 */
  $( window ).scroll( function( e ) {
    //variables globales
    alto=$(window).height()/2;
    posy=$(window).scrollTop();
    pcty=parseInt(posy*100/alto,10);

    $('.js-parallaxScroll').each(function(index, el){
      tempy=pcty*parseInt($(el).attr("data-movimientopxy"),12)/100*-1;
      $(el).css({
        '-ms-transform': 'translateY('+tempy+'px)',
        '-webkit-transform': 'translateY('+tempy+'px)',
        'transform': 'translateY('+tempy+'px)'
      });
    });
  });

/**
 * hace scroll al panel de la pregunta que desliega y le añade clase "active" al padre
 * @param  {[type]} e)    {                              var $card [description]
 * @param  {[type]} 500);               } [description]
 * @return {[type]}       [description]
 */
  $('.collapse').on('shown.bs.collapse', function(e) {
    var $card = $(this).closest('.card-header');
    var $card2 = $(this).closest('.card').find(".card-header");
    $card2.addClass("active");
    //console.log('pasa '+$(this).attr('id'));
    //PLG_scrollTo(0.5,$card,0,-120)

    //PLG_scrollTo(0.5,$card,0,-120);

  });



};


